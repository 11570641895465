'use client'

import { Form, Input } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressButtons from '../ProgressButtons'

interface SpecificProjectStepProps {
  goBack: () => void
}

const SpecificProjectStep: React.FC<SpecificProjectStepProps> = ({
  goBack,
}) => {
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    if (steps[currentStep + 1]) {
      setCurrentStep(currentStep + 1)
    } else {
      setSteps({
        ...steps,
        [currentStep]: values,
      })
      setCurrentStep(currentStep + 1)
    }
  }

  return (
    <Form
      onFinish={finish}
      autoComplete='off'
      layout='vertical'
      requiredMark='optional'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col'>
            <Form.Item
              label='Project Budget'
              name='budget'
              rules={[{ type: 'string' }]}
            >
              <Input.TextArea placeholder='e.g., $1,000,000 for building a spaceship prototype' />
            </Form.Item>
            <Form.Item
              label='Project Timeline'
              name='timeline'
              rules={[{ type: 'string' }]}
            >
              <Input.TextArea placeholder='e.g., Start: Jan 2024, End: Dec 2024' />
            </Form.Item>
            <Form.Item
              label='Previous Grants'
              name='previousGrants'
              rules={[{ type: 'string' }]}
            >
              <Input.TextArea placeholder='e.g., Received $500,000 from NASA in 2022' />
            </Form.Item>

            <Form.Item
              label='Additional Information'
              name='additionalInfo'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                placeholder='e.g., We plan to collaborate with SpaceX for rocket components'
                rows={5}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <ProgressButtons goBack={goBack} />
    </Form>
  )
}

export default SpecificProjectStep
