'use client'

import { Form, Input } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { companyInfoPrompt } from '@/utils/prompts'

import ProgressButtons from '../ProgressButtons'
import ImportBody from '../../Admin/Modals/FileHub/ImportBody'

interface UploadDocumentStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  goBack: () => void
}

const UploadDocumentStep: React.FC<UploadDocumentStepProps> = ({
  getAnswerWrapper,
  goBack,
}) => {
  const { setCurrentStep, currentStep, steps, setSteps } =
    useGrantApplicationState()
  const { selectedConversation } = useDrawerState()

  const finish = async (values: { [x: string]: string }) => {
    if (steps[currentStep + 1]) {
      setCurrentStep(currentStep + 1)
    } else {
      setSteps({
        ...steps,
        [currentStep]: values,
      })
      setCurrentStep(currentStep + 1)
      await getAnswerWrapper(
        companyInfoPrompt(
          steps[0].companyUrl,
          values.address,
          values.numberOfEmployees,
          values.annualRevenue,
          steps[0].additionalInfo
        ),
        true
      )
    }
  }

  return (
    <Form
      onFinish={finish}
      autoComplete='off'
      requiredMark='optional'
      layout='vertical'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col'>
              <Form.Item
                label='Address'
                name='address'
                rules={[{ type: 'string' }]}
              >
                <Input placeholder='Address' />
              </Form.Item>
              <Form.Item
                label='Number of employees'
                name='numberOfEmployees'
                rules={[{ type: 'string' }]}
              >
                <Input placeholder='Number of employees' />
              </Form.Item>
              <Form.Item
                label='Annual revenue'
                name='annualRevenue'
                rules={[{ type: 'string' }]}
              >
                <Input placeholder='Annual revenue' />
              </Form.Item>
            </div>
            <div className='flex flex-col'>
              <p className='mb-2'>
                Add documents here to give the AI more information to match you
                with grants, and draft the proposal.
              </p>
              <ImportBody conversationId={selectedConversation} />
            </div>
          </div>
        </div>
      </div>
      <ProgressButtons goBack={goBack} />
    </Form>
  )
}

export default UploadDocumentStep
