'use client'

import { RiSparklingFill } from '@remixicon/react'
import { message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { getAnswerDraft } from '@/service/Chatbot'
import { generateUUID, markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import {
  forkGrantThread,
  getCurrentStepNumQuestions,
  getStepMessage,
  getStepQuestions,
  isLastStep,
} from '@/utils/grant'
import { generateProposalDraftPrompt } from '@/utils/prompts'
import { enhanceGrantAlignmentPrompt } from '@/utils/prompts/enhanceGrantAlignmentPrompt'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

import { GrantApplicationMode } from '@/types/grants'

interface GrantInfoStepProps {
  getAnswerWrapper: (
    question: string,
    streaming: boolean,
    conversationId?: string,
    json?: boolean
  ) => Promise<void>
  loading?: boolean
  setLoading: (loading: boolean) => void
  goBack: () => void
}

const GrantInfoStep: React.FC<GrantInfoStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  goBack,
}) => {
  const {
    mode,
    questions,
    steps,
    setCurrentStep,
    currentStep,
    setSteps,
    setSections,
    setQuestions,
  } = useGrantApplicationState()
  const { t } = useTranslation()
  const { setSelectedConversation, mutateConversations } = useDrawerState()
  const [numQuestions, setNumQuestions] = useState<number>(0)
  const [enhancing, setEnhancing] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')
  const stepMessage = getStepMessage(questions, steps, currentStep)

  useEffect(() => {
    if (!numQuestions && steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep]?.numQuestions ?? 1)
    }
    additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
  }, [steps[currentStep]])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      const newNumQuestions = getCurrentStepNumQuestions(
        questions,
        steps,
        currentStep
      )
      setNumQuestions(newNumQuestions)
      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: newNumQuestions,
        },
      })
      additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
      setEnhancing(false)
    }
  }, [loading])

  const finish = async () => {
    if (steps[currentStep + 1]) {
      setCurrentStep(currentStep + 1)
    } else {
      if (mode === GrantApplicationMode.MATCHING) {
        setSelectedConversation(undefined)
        setSteps({
          ...steps,
          [currentStep]: {
            ...steps[currentStep],
            additionalInfo: additionalInfoRef.current,
          },
        })
        return
      } else {
        setCurrentStep(currentStep + 1)
      }

      const nextStep = currentStep + 1
      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          additionalInfo: additionalInfoRef.current,
        },
        [nextStep]: {
          numQuestions: 1,
        },
      })
      setCurrentStep(nextStep)

      const numRetries = 0
      let success = false
      while (numRetries < 3 && !success) {
        try {
          await getAnswerWrapper(
            generateProposalDraftPrompt(),
            false,
            undefined,
            true
          )
          success = true
        } catch (error) {
          console.error(error)
        }
      }

      if (!success) {
        message.error('Failed to generate proposal draft. Please try again.')
        setCurrentStep(nextStep - 1)
        setSteps({
          ...steps,
          [nextStep]: undefined,
        })
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
        setQuestions((prevState) => prevState.slice(0, -1))
      }
    }
  }

  const enhance = async (values: { input: string }) => {
    if (isLastStep(currentStep, steps)) {
      setEnhancing(true)
      await getAnswerWrapper(
        enhanceGrantAlignmentPrompt(
          questions[currentStep - 1]?.messages[1]?.message ?? '',
          values.input
        ),
        true
      )
    } else {
      await regenerate(values)
    }
  }

  const regenerate = async (values?: { input: string }) => {
    const id = generateUUID()
    setLoading(true)
    const question = enhanceGrantAlignmentPrompt(
      questions[currentStep - 1]?.messages[1]?.message ?? '',
      values?.input
    )
    const questionResult = await getAnswerDraft(
      true,
      question,
      1,
      getStepQuestions(questions, steps, currentStep),
      undefined,
      false,
      undefined,
      id
    )
    await forkGrantThread(
      id,
      questions,
      steps,
      currentStep,
      question,
      mode,
      questionResult
    )
    mutateConversations()
    setSelectedConversation(id)
    setLoading(false)
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        ref={answerRef}
        className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full flex-col min-h-full rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !stepMessage ? 'h-auto' : ''
          )}
        >
          {!loading && stepMessage ? (
            <>
              <div className='markdown-answer mb-6 h-full grow break-words text-left text-sm sm:text-base'>
                {markdown(stepMessage as string, loading)}
              </div>
              {!loading && (
                <>
                  <TextAreaWithUpload
                    inputRef={additionalInfoRef}
                    onFinish={enhance}
                    finishIcon={<RiSparklingFill className='size-5' />}
                    finishText='Enhance'
                    formErrorMessage='Please add enhancement instructions.'
                    placeholder='How would you like to enhance this information? For a more detailed alignment with historical giving patterns for non-profit organizations, you may provide the EIN number.'
                    uploadTooltip='Upload files'
                  />
                  {!isLastStep(currentStep, steps) && (
                    <div className='mt-2 text-[#ddb66b]'>
                      {t('thread-fork-warning')}
                    </div>
                  )}
                </>
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingAnimation}
              text={[
                'Identifying the selected grant for detailed analysis...',
                'Gathering specific data on funding eligibility and requirements...',
                'Analyzing grant terms to ensure alignment with your needs...',
                'Compiling relevant insights...',
                'Finalizing data for selected grant...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        nextText={mode === GrantApplicationMode.MATCHING ? 'Finish' : undefined}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default GrantInfoStep
