import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { cn } from '@/utils/clsx'

interface ProgressDotsProps {
  currentStep: number
  totalSteps: number
}

const ProgressDots: React.FC<ProgressDotsProps> = ({
  currentStep,
  totalSteps,
}) => {
  const { setCurrentStep } = useGrantApplicationState()
  return (
    <div className='flex items-center gap-1 sm:gap-2'>
      {Array.from({
        length: totalSteps,
      }).map((_, index) => (
        <div
          key={index}
          onClick={() => {
            index <= currentStep ? setCurrentStep(index) : undefined
          }}
          className={cn(
            'w-full h-0.5 bg-on-surface dark:bg-dark-on-surface rounded-full transition-all py-[1px]',
            index <= currentStep
              ? 'bg-on-surface dark:bg-dark-on-surface cursor-pointer'
              : 'bg-on-surface/20 dark:bg-dark-on-surface/20'
          )}
        />
      ))}
    </div>
  )
}

export default ProgressDots
