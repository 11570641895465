'use client'

import { Form, Input } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressButtons from '../ProgressButtons'

import { IQuestion } from '@/types/chatbot'

interface PreferredGrantsStepProps {
  gatherGrants: (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => Promise<void>
  goBack: () => void
}

const PreferredGrantsStep: React.FC<PreferredGrantsStepProps> = ({
  gatherGrants,
  goBack,
}) => {
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    if (steps[currentStep + 1]) {
      setCurrentStep(currentStep + 1)
    } else {
      setSteps({
        ...steps,
        [currentStep]: values,
      })
      setCurrentStep(currentStep + 1)
      let preferredGrants
      if (values.preferredGrants) {
        preferredGrants = `You must focus on finding the grants that fit this description or type: ${values.preferredGrants}`
      }
      await gatherGrants(preferredGrants)
    }
  }

  return (
    <Form
      onFinish={finish}
      autoComplete='off'
      layout='vertical'
      requiredMark='optional'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col'>
            <p className='mb-4'>
              If you are already aware of relevant grants you can list them
              here.
            </p>

            <Form.Item name='preferredGrants' rules={[{ type: 'string' }]}>
              <Input.TextArea placeholder='SBIR/STTR' rows={5} />
            </Form.Item>
          </div>
        </div>
      </div>
      <ProgressButtons goBack={goBack} />
    </Form>
  )
}

export default PreferredGrantsStep
